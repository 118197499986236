import styled from 'styled-components'

export const Content = styled.div`
width: 100%;
  height: auto;
  
  /* display: flex;
  justify-content: center; */
  /* width: calc(100% - 5vw);
  margin: 75px auto; */
  /* margin-top: 8rem; */
`


